import { h } from "preact";
import { useId, useState } from "preact/hooks";
import { VisuallyHidden } from "@shared/Components/VisuallyHidden/visually-hidden";

export interface ServiceExpandableProps {
  content: string;
  readMoreText: string;
  closeText: string;
  ariaButtonText: string;
}

export const ServiceExpandable = ({
  content,
  readMoreText,
  closeText,
  ariaButtonText,
}: ServiceExpandableProps): h.JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  const contentId = useId();
  const buttonId = useId();
  return (
    <div className="service-expandable">
      <button
        id={buttonId}
        type="button"
        className="flex flex--split service-expandable__readmore link"
        onClick={() => setOpen(!open)}
        aria-expanded={open}
        aria-controls={contentId}
      >
        <span>{open ? closeText : readMoreText}</span>
        <svg
          className={`service-expandable__readmore__icon ${open ? "service-expandable__readmore__icon--open" : ""}`}
          focusable="false"
          aria-hidden="true"
        >
          <use xlinkHref="#ice-chevron--down"></use>
        </svg>
        <VisuallyHidden>{ariaButtonText}</VisuallyHidden>
      </button>
      <div className={`service-expandable__container ${open ? "service-expandable__container--open" : ""}`}>
        <div
          className={`service-expandable__content`}
          id={contentId}
          role={"region"}
          aria-labelledby={buttonId}
          aria-hidden={!open}
        >
          <span dangerouslySetInnerHTML={{ __html: content }}></span>
        </div>
      </div>
    </div>
  );
};
