import { BusinessUnit } from "@shared/Utils";

export type GA4Event = {
  event: "add_to_cart" | "remove_from_cart" | "view_cart";
  ecommerce: BaseGA4Ecommerce;
};

export type BaseGA4Ecommerce = {
  currency: "NOK";
  value: number;
  items: GA4Product[];
};

export type GA4Product = {
  item_id: string;
  item_name: string;
  item_brand: "Ice";
  item_variant: string; // variant
  item_binding?: string; // dimension 18, "nei" | "n" n = antall måneder med bindingstid
  item_business_unit?: "B2C" | "B2B"; // Dimension5, business unit. "B2C" eller "B2B"
  affiliation: "ice.no"; // Can be changed to an affiliate id when that is implemented. E.g. "bytt.no".
  quantity: number; // quantity
  price: number; // price
  item_bundle?: "Mobilt Bredbånd" | "Mobilabonnement";
  coupon?: string; // probably not relevant?
  discount?: number; // probably not relevant?
  index?: number;
  item_list_id?: string; // probably not relevant?
  item_list_name?: string; // probably not relevant?
  item_product_type?: string; // "Service" | "SIM" | "Router"
  item_category?: string; // "Service"
  item_category2?: string; // "MBB" | "Mobilabonnement"
  item_category3?: string; // "Data"
  item_category4?: string;
  item_category5?: string;
  location_id?: string;
  item_addons?: string;
  add_to_cart_location?: "plp" | "pdp";
};

export const HARDWARE_TYPE_VALUES = [
  "Mobiltelefoner",
  "Routers",
  "Smartklokke",
  "Nettbrett",
  "Mobiltilbehor",
  "Pentbrukt",
  "Accessories",
  "Mobil",
] as const;

export type HardwareType = (typeof HARDWARE_TYPE_VALUES)[number];
export const ValidHardwareTypes = new Set<HardwareType>(HARDWARE_TYPE_VALUES);

export const EQUIPMENT_TYPE_VALUES = ["Terminal", "Accessories"] as const;
export type EquipmentType = (typeof EQUIPMENT_TYPE_VALUES)[number];

export type GA4HardwareProduct = {
  item_name: string;
  affiliation: "nettbutikk.ice.no";
  index: number;
  item_brand: string;
  item_category: "Hardware";
  item_category2: EquipmentType;
  item_category3: HardwareType;
  item_category4: "Ny" | "Brukt";
  item_list_name: string;
  item_business_unit: BusinessUnit;
  item_product_type: string;
  price?: number;
  quantity: number;
  item_gtin?: string | null;
};

export type GA4ProductCategories = {
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
};

export enum EcommerceEvents {
  TrackingInstantiated = "ecommerce_instantiated",
  TrackProductDetails = "ecommerce_track_product-details",
  TrackProductClick = "ecommerce_track_product-click",
  TrackAddToCart = "ecommerce_track_add_to_cart",
  TrackRemoveFromCart = "ecommerce_track_remove_from_cart",
}

export enum GAEvent {
  ProductDetail = "productDetail",
  ProductClick = "productClick",
  ProductImpression = "impressions",
  AddToCart = "addToCart",
  RemoveFromCart = "removeFromCart",
  ViewCart = "view_cart",
}

export type ProductEvent = CustomEvent<{ offeringId: string }>;
export type ProductsEvent = CustomEvent<{ offeringIds: string[]; productPage: "plp" | "pdp" }>;

type ProductId = string;
type PlacementMetadata = {
  position: string;
  list?: string;
};

export type GA4EcommerceViewCartService = {
  item_id: string;
  item_name: string;
  affiliation: string;
  item_brand: string;
  price: number;
  item_variant: string;
  item_business_unit: string;
  item_bundle: string;
  item_product_type: string;
  item_binding: string;
  quantity: number;
};

export type GA4ViewCartEvent = {
  event: GAEvent.ViewCart;
  ecommerce: {
    currency: string;
    value: number;
    items: GA4EcommerceViewCartService[];
  };
};

export type ProductPlacementMetadata = Record<ProductId, PlacementMetadata>;

export type GA4OrderingButtonClick = {
  event: "button_click";
  element_category: "mobilabonnement_bestill";
  element_cta: "bestill" | "bestill_sticky";
  item_name: string;
  item_business_unit: "B2C" | "B2B";
};

export type GA4GoToCheckoutButtonClick = {
  event: "button_click";
  element_category: "mobilabonnement_bestill" | "mobilt-bredbånd_bestill" | "mobilabonnement_fortsett_å_handle";
  element_cta: "til bestilling" | "fortsett å handle";
  business_unit: "B2C" | "B2B";
};

export type GA4HardwareSelectItemEvent = {
  event: "select_item";
  ecommerce: {
    item_list_name: string;
    items: GA4HardwareProduct[];
  };
};
